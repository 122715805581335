import React, {useEffect} from "react";
import './App.scss';
import {AppRoutes} from "../routing/Routes";

function App() {

    useEffect(() => {
        document.documentElement.style.overflow = 'auto';
    }, []);

  return (
    <>
        <AppRoutes />
    </>
  );
}

export default App;
