import {FC, lazy, ReactNode, Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import React from "react";
import Landing from "../App/Pages/landing/Landing";
import MasterLayout from "./MasterLayout";

type WithChildren = {
    children?: ReactNode
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    return <Suspense fallback={'loading'}>{children}</Suspense>
}

const ProductPage = lazy(() => import("../App/Pages/product-page/ProductPage"))

export const AppRoutes = () => {
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route
                    path='/'
                    element={
                        <SuspensedView>
                            <Landing />
                        </SuspensedView>
                    }
                />

                <Route
                    path='/:year/:slug/:tab'
                    element={
                        <SuspensedView>
                            <ProductPage />
                        </SuspensedView>
                    }
                />

                <Route
                    path='/:slug/:tab'
                    element={
                        <SuspensedView>
                            <ProductPage />
                        </SuspensedView>
                    }
                />

                {/*<Route path='*' element={<Navigate to='/404' replace/>}/>*/}
            </Route>
        </Routes>
    )
}
