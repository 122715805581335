
import reward1 from "../assets/reward/USAWR_SilverMedal_2023.png";
import reward2 from "../assets/reward/USAWR_SilverMedal_2024.png";

//  kardeneli-red-dry 2019
import photo19_k19_1 from "../assets/products/5-1.png";
import photo19_k19_2 from "../assets/products/k19_0693.jpg";
import photo19_k19_3 from "../assets/products/K19_0694.jpg";

// kardeneli-red-dry 2019 origin
import photo19_k19_2_origin from "../assets/products/origin/k19_0693.JPG";
import photo19_k19_3_origin from "../assets/products/origin/K19_0694.JPG";

//  kardeneli-red-dry 2021
import photo21_k21_1 from "../assets/products/5-1-3.png";
import photo21_k21_2 from "../assets/products/Karden_21.jpg";
import photo21_k21_3 from "../assets/products/K21.jpg";

//  kardeneli-red-dry 2021 origin
import photo21_k21_2_origin from "../assets/products/origin/Karden_21.JPG";
import photo21_k21_3_origin from "../assets/products/origin/K21.JPG";

//  kardeneli-red-dry 2022
import photo21_k22_1 from "../assets/products/kardenelli_2022_front.jpg";
import photo21_k22_4 from "../assets/products/K22_0697.jpg";
import photo21_k22_2 from "../assets/products/K22_0689.jpg";
import photo21_k22_3 from "../assets/products/K22_0698.jpg";

//  kardeneli-red-dry 2022 origin
import photo21_k22_4_origin from "../assets/products/origin/K22_0697.JPG";
import photo21_k22_2_origin from "../assets/products/origin/K22_0689.JPG";
import photo21_k22_3_origin from "../assets/products/origin/K22_0698.JPG";


//  kardeneli-red-dry 2023
import photo21_k23_1 from "../assets/products/kardenelli_2023_front.jpg";
import photo21_k23_2 from "../assets/products/kardenelli_2023_back.jpg";


//  velise-amber-dry-kvevri 2019
import photo21_vel19_1 from "../assets/products/4-1.png";
import photo21_vel19_2 from "../assets/products/V19_0645.jpg";
import photo21_vel19_3 from "../assets/products/V19_0648.jpg";
import photo21_vel19_4 from "../assets/products/V19_0646.jpg";

//  velise-amber-dry-kvevri 2019
import photo21_vel19_2_origin from "../assets/products/origin/V19_0645.JPG";
import photo21_vel19_3_origin from "../assets/products/origin/V19_0648.JPG";
import photo21_vel19_4_origin from "../assets/products/origin/V19_0646.JPG";

// Rewards modal files
import kard_2019 from "../assets/reward/USA_Wine_Ratings_-_5336_Kardeneli_2019_by_Sommelier_Selection_LLC.jpg";
import kard_2021 from "../assets/reward/USA_Wine_Ratings_-_5848_Kardeneli_Saperavi_2021_by_Sommelier_Selection_llc.jpg";
import kard_2022 from "../assets/reward/USA_Wine_Ratings_-_5849_Kardeneli_Saperavi_2022_by_Sommelier_Selection_llc_page-0001.jpg";
import velise from "../assets/reward/USA_Wine_Ratings_-_5337_Velise_by_Sommelier_Selection_LLC_page-0001.jpg";


export const Products = [
    {
        id: 2,
        photo: photo21_k21_1,
        name: "Kardeneli Red 2021",
        cardDetails: "",
        details: "",
        slug: 'kardeneli-red-dry',

        year: '2021',
        reward_img: reward2,
        reward_file: kard_2021,
        gallery: [photo21_k21_1, photo21_k21_2, photo21_k21_1, photo21_k21_3 ],
        gallery_origin: [photo21_k21_1, photo21_k21_2_origin, photo21_k21_1, photo21_k21_3_origin ],
        general: {
            cover: false,
            details: "Red, Dry Natural Wine, Vintage: 2021",
            varieties: "Saperavi 100%",
            alcohol: "14.1%",
            origin: "Akhoebi Microzone, Kardenakhi village, Kakheti region, East Georgia",
            fermentation: "Naturally fermented by traditional Georgian way, maceration goes with the grape skins.",
            description: <div className="product_text_block">
                <p className="product_text_desc">
                    <span>Red Dry Wine Crafted from the Heart of Georgia's Vineyards</span>
                </p>
                <p className="product_text_desc">
                    Welcome to the exquisite experience of <span>Kardeneli 2021 Saperavi,</span> a wine that embodies the
                    soul of Kardenakhi village, nestled in the heart of Georgia’s renowned Kakheti wine region.
                    Handcrafted with passion and tradition, this limited-edition wine is cultivated from
                    centuries-old Saperavi vines, grown in the mineral-rich soils of the Akhoebi-Tsaraphi
                    microzone, ensuring the finest quality from every harvest.
                </p>

                <p className="product_text_desc">
                    <span>A Masterpiece from the Vineyards</span>
                </p>

                <p className="product_text_desc">
                    Our family vineyards, perched on the terraces of Kardenakhi, overlook the majestic Alazani
                    Valley. Rich, stony, clay dry-farmed soils imbued with natural minerals create a harmonious
                    environment for growing grapes that are both healthy and flavorful.
                </p>

                <p className="product_text_desc">
                    At <span>Kardeneli</span>, every grape is carefully nurtured, focusing on quality over
                    quantity. The result? Grapes that yield a high sugar content, rich acids, and essential
                    nutrients, making for a truly exceptional wine.
                </p>

                <p className="product_text_desc">
                    <span>Why Choose Kardeneli 2021 Red?</span>
                </p>

                <p className="product_text_desc">
                    <p>• <span>Handcrafted Excellence</span> – Family-owned and maintained for generations with
                        minimal intervention, allowing nature to thrive in balance.</p>
                    <p>• <span>Naturally Fermented</span> on wild yests– Fermented on the skins for four weeks
                        without added yeast, ensuring the purest expression of natural flavors.</p>
                    <p>• <span>No Pressing Needed, Free-run wine</span> – Gravity-fed extraction preserves the
                        purity of the wine, free from bitterness and harsh tannins.</p>
                    <p>• <span>Aged to Perfection</span> – fermented with minimal sulfur, aged and rests in
                        bottles since bottling after one year of ravest, ensure secondary fermentation for taste
                        perfection, Kardeneli offers a clean, pure finish, with an alcohol content of 14.1%,
                        ensuring longevity and further aging potential in bottles.</p>
                </p>


                <p className="product_text_desc">
                    <span>Taste the Legacy</span>
                </p>

                <p className="product_text_desc">
                    With each sip, experience the carefully layered flavors
                    of <span>Kardeneli 2021 Saperavi</span>:
                </p>

                <p className="product_text_desc">
                    <p>• <span>Aromas </span> of intense blackberry, dark cherry, and plum accented by toasted
                        cinnamon and clove.</p>
                    <p>• A <span>full-bodied palate </span> of juicy fruit with chewy tannins and a long, smooth
                        finish.</p>
                    <p>• <span>No Pressing Needed, Free-run wine</span> – Gravity-fed extraction preserves the
                        purity of the wine, free from bitterness and harsh tannins.</p>
                    <p>• Notes of <span>minerality,</span> reflecting the unique terroir of Kardenakhi(Kardeneli
                        Maciej Notes).</p>
                </p>

                <p className="product_text_desc">
                    This wine evolves with every minute in the glass, revealing deeper complexities of flavor.
                    Enjoy upon opening, after 15 minutes, and again after an hour to savor its full range.
                </p>

                <p className="product_text_desc">
                    <span>Limited Production</span>
                </p>

                <p className="product_text_desc">
                    Only <span>2,200 bottles of Kardeneli 2021</span> have been produced.
                    Store in your personal cellar for up to 7 years, as the premium cork ensures its continued
                    aging and development over time.
                </p>

                <p className="product_text_desc">
                    <span>The Perfect Companion for Celebrations</span>
                </p>

                <p className="product_text_desc">
                    Whether shared with friends or enjoyed in quiet reflection, <span>Kardeneli 2021</span> is a wine that elevates any occasion. Its moderate body and smooth tannins make it an ideal companion for happy, friendly gatherings. <span>A glass of Kardeneli is a toast to good health, friendship, and the art of winemaking.</span>
                </p>

                <p className="product_text_desc">
                    <span>Raise your glass to the beauty of tradition and the joy of discovery with Kardeneli 2021.</span>
                </p>


            </div>,
            quote: "",
        },
        testing_notes: {
            with_score: {
                score: '91',
                date: '28.06.2023',
                name: 'Peter Moser',
                overview: 'Strong ruby colour, purple reflections, broader ochre lightening on the edge. Fresh red forest berryish fruit, delicate hints of strawberries, orange zest, floral nuances. Juicy, round and underpinned with fine fruit, round tannins, some figs on the finish, mineral and already accessible.',
            },
            common_notes: [
                {
                    title: 'In  July 2024 by judge pannel USA wine competition:',
                    text: 'Intense blackberry, dark cherry, and plum aromas are complemented by toasted cinnamon and clove, leading to a full-bodied palate with chewy tannins and a long, layered finish.'
                }
            ],
            description:  {
                title: '',
                text: 'The 2021 vintage was another stellar year, with favorable weather ensuring a harmonious balance of sugar and acidity in the grapes. This vintage showcases aromas of intense blackberry, dark cherry, and plum, complemented by hints of toasted cinnamon and clove'
            }
        },
        video: null
    },
    {
        id: 1,
        photo: photo19_k19_1,
        name: "Kardeneli Red 2019",
        cardDetails: "",
        details: "Red, Dry Natural Wine, Vintage: 2019",
        slug: 'kardeneli-red-dry',

        year: '2019',
        reward_img: reward1,
        reward_file: kard_2019,
        gallery: [photo19_k19_1, photo19_k19_2, photo19_k19_1, photo19_k19_3],
        gallery_origin: [photo19_k19_1, photo19_k19_2_origin, photo19_k19_1, photo19_k19_3_origin],
        general: {
            cover: false,
            details: "Red, Dry Natural Wine, Vintage: 2019",
            varieties: "Saperavi 100%",
            alcohol: "14.1%",
            origin: "Akhoebi Microzone, Kardenakhi village, Kakheti region, East Georgia",
            fermentation: "Naturally fermented by traditional Georgian way, maceration goes with the grape skins.",
            description: <div className="product_text_block">
                <p className="product_text_desc">
                    <span>Red Dry Wine Crafted from the Heart of Georgia's Vineyards</span>
                </p>
                <p className="product_text_desc">
                    Welcome to the exquisite experience of <span>Kardeneli 2019 Saperavi</span>, a wine that embodies the
                    soul of Kardenakhi village, nestled in the heart of Georgia’s renowned Kakheti wine region.
                    Handcrafted with passion and tradition, this limited-edition wine is cultivated from
                    centuries-old Saperavi vines, grown in the mineral-rich soils of the Akhoebi-Tsaraphi
                    microzone, ensuring the finest quality from every harvest.
                </p>

                <p className="product_text_desc">
                    <span>A Masterpiece from the Vineyards</span>
                </p>

                <p className="product_text_desc">
                    Our family vineyards, perched on the terraces of Kardenakhi, overlook the majestic Alazani
                    Valley. Rich, stony, clay dry-farmed soils imbued with natural minerals create a harmonious
                    environment for growing grapes that are both healthy and flavorful.
                </p>

                <p className="product_text_desc">
                    At <span>Kardeneli</span>, every grape is carefully nurtured, focusing on quality over
                    quantity. The result? Grapes that yield a high sugar content, rich acids, and essential
                    nutrients, making for a truly exceptional wine.
                </p>

                <p className="product_text_desc">
                    <span>Why Choose Kardeneli 2019 Red?</span>
                </p>

                <p className="product_text_desc">
                    <p>• <span>Handcrafted Excellence</span> – Family-owned and maintained for generations with
                        minimal intervention, allowing nature to thrive in balance.</p>
                    <p>• <span>Naturally Fermented</span> on wild yests– Fermented on the skins for four weeks
                        without added yeast, ensuring the purest expression of natural flavors.</p>
                    <p>• <span>No Pressing Needed, Free-run wine</span> – Gravity-fed extraction preserves the
                        purity of the wine, free from bitterness and harsh tannins.</p>
                    <p>• <span>Aged to Perfection</span> – fermented with minimal sulfur, aged and rests in
                        bottles since bottling after one year of ravest, ensure secondary fermentation for taste
                        perfection, Kardeneli offers a clean, pure finish, with an alcohol content of 14.1%,
                        ensuring longevity and further aging potential in bottles.</p>
                </p>


                <p className="product_text_desc">
                    This wine evolves with every minute in the glass, revealing deeper complexities of flavor.
                    Enjoy upon opening, after 15 minutes, and again after an hour to savor its full range
                </p>


                <p className="product_text_desc">
                    <span>Taste the Legacy</span>
                </p>

                <p className="product_text_desc">
                    With each sip, experience the carefully layered flavors
                    of <span>Kardeneli 2019 Saperavi</span>:
                </p>

                <p className="product_text_desc">
                    <p>• <span>Aromas </span> of intense blackberry, dark cherry, and plum accented by toasted
                        cinnamon and clove.</p>
                    <p>• A <span>full-bodied palate </span> of juicy fruit with chewy tannins and a long, smooth
                        finish.</p>
                    <p>• <span>No Pressing Needed, Free-run wine</span> – Gravity-fed extraction preserves the
                        purity of the wine, free from bitterness and harsh tannins.</p>
                    <p>• Notes of <span>minerality,</span> reflecting the unique terroir of Kardenakhi(Kardeneli
                        Maciej Notes).</p>
                </p>

                <p className="product_text_desc">
                    This wine evolves with every minute in the glass, revealing deeper complexities of flavor.
                    Enjoy upon opening, after 15 minutes, and again after an hour to savor its full range.
                </p>

                <p className="product_text_desc">
                    <span>Limited Production</span>
                </p>

                <p className="product_text_desc">
                    Only <span>2,500 bottles of Kardeneli 2019</span> have been produced.
                    Store in your personal cellar for up to 7 years, as the premium cork ensures its continued
                    aging and development over time.
                </p>

                <p className="product_text_desc">
                    <span>The Perfect Companion for Celebrations</span>
                </p>

                <p className="product_text_desc">
                    Whether shared with friends or enjoyed in quiet reflection, Kardeneli 2019 is a wine that elevates any occasion. Its moderate body and smooth tannins make it an ideal companion for happy, friendly gatherings. <span>A glass of Kardeneli is a toast to good health, friendship, and the art of winemaking.</span>
                </p>

                <p className="product_text_desc">
                    <span>Raise your glass to the beauty of tradition and the joy of discovery with Kardeneli 2019.</span>
                </p>


            </div>,
            quote: "",
        },
        testing_notes: {
            with_score: null,
            common_notes: [
                {
                    title: 'Testing notes at USA Wine Competition 2023:',
                    text: 'Dark berry and black cherry flavours with medium acidity. Hint of liquorice carry over to the mid-palate with long finish.'
                }
            ],
            description:  {
                title: 'Kardeneli Saperavi 2019.',
                text: 'Widely regarded as one of the best vintages in recent history, 2019 provided ideal growing conditions with a perfect balance of rain, sunlight, and temperature. These elements aligned to create a remarkable year, allowing the grapes to ripen evenly with exceptional sugar content and well-preserved acidity. The result is a wine bursting with vibrant flavors of ripe fruit, a rich, smooth body, and a long, elegant finish. The balanced weather conditions made 2019 a benchmark for quality at Kardeneli.'
            }
        },
        video: null
        // video: [
        //     {
        //         lang: 'eng',
        //         url: 'https://videocdn.cdnpk.net/videos/407ebd14-f15d-443a-8a1d-a50f10e0b0ce/horizontal/previews/videvo_watermarked/large.mp4'
        //     },
        //     {
        //         lang: 'ro',
        //         url: 'https://media.istockphoto.com/id/473246265/video/earth-night-asia.mp4?s=mp4-640x640-is&k=20&c=qRbgkoA8qSPNICMlupcCk14QfQJ8qBZEImICqG9ebFo='
        //     },
        // ]
    },
    {
        id: 3,
        photo: photo21_k22_1,
        name: "Kardeneli Red 2022",
        cardDetails: "",
        details: "",
        slug: 'kardeneli-red-dry',

        year: '2022',
        reward_img: reward2,
        reward_file: kard_2022,
        gallery: [photo21_k22_1, photo21_k22_2, photo21_k22_3, photo21_k22_4],
        gallery_origin: [photo21_k22_1, photo21_k22_2_origin, photo21_k22_3_origin, photo21_k22_4_origin],
        general: {
            cover: false,
            details: "Red, Dry Natural Wine, Vintage: 2022",
            varieties: "Saperavi 100%",
            alcohol: "14.1%",
            origin: "Akhoebi Microzone, Kardenakhi village, Kakheti region, East Georgia",
            fermentation: "Naturally fermented by traditional Georgian way, maceration goes with the grape skins.",
            description: <div className="product_text_block">
                <p className="product_text_desc">
                    <span>Red Dry Wine Crafted from the Heart of Georgia's Vineyards</span>
                </p>
                <p className="product_text_desc">
                    Welcome to the exquisite experience of <span>Kardeneli 2022 Saperavi,</span> a wine that embodies the
                    soul of Kardenakhi village, nestled in the heart of Georgia’s renowned Kakheti wine region.
                    Handcrafted with passion and tradition, this limited-edition wine is cultivated from
                    centuries-old Saperavi vines, grown in the mineral-rich soils of the Akhoebi-Tsaraphi
                    microzone, ensuring the finest quality from every harvest.
                </p>

                <p className="product_text_desc">
                    <span>A Masterpiece from the Vineyards</span>
                </p>

                <p className="product_text_desc">
                    Our family vineyards, perched on the terraces of Kardenakhi, overlook the majestic Alazani
                    Valley. Rich, stony, clay dry-farmed soils imbued with natural minerals create a harmonious
                    environment for growing grapes that are both healthy and flavorful.
                </p>

                <p className="product_text_desc">
                    At <span>Kardeneli</span>, every grape is carefully nurtured, focusing on quality over
                    quantity. The result? Grapes that yield a high sugar content, rich acids, and essential
                    nutrients, making for a truly exceptional wine.
                </p>

                <p className="product_text_desc">
                    <span>Why Choose Kardeneli 2022 Red?</span>
                </p>

                <p className="product_text_desc">
                    <p>• <span>Handcrafted Excellence</span> – Family-owned and maintained for generations with
                        minimal intervention, allowing nature to thrive in balance.</p>
                    <p>• <span>Naturally Fermented</span> on wild yests– Fermented on the skins for four weeks
                        without added yeast, ensuring the purest expression of natural flavors.</p>
                    <p>• <span>No Pressing Needed, Free-run wine</span> – Gravity-fed extraction preserves the
                        purity of the wine, free from bitterness and harsh tannins.</p>
                    <p>• <span>Aged to Perfection</span> – fermented with minimal sulfur, aged and rests in
                        bottles since bottling after one year of ravest, ensure secondary fermentation for taste
                        perfection, Kardeneli offers a clean, pure finish, with an alcohol content of 14.%,
                        ensuring longevity and further aging potential in bottles.</p>
                </p>


                <p className="product_text_desc">
                    <span>Taste the Legacy</span>
                </p>

                <p className="product_text_desc">
                    With each sip, experience the carefully layered flavors
                    of <span>Kardeneli 2022 Saperavi</span>:
                </p>

                <p className="product_text_desc">
                    <p>• <span>Aromas </span> of intense blackberry, dark cherry, and plum accented by toasted
                        cinnamon and clove.</p>
                    <p>• A <span>full-bodied palate </span> of juicy fruit with chewy tannins and a long, smooth
                        finish.</p>
                    <p>• <span>No Pressing Needed, Free-run wine</span> – Gravity-fed extraction preserves the
                        purity of the wine, free from bitterness and harsh tannins.</p>
                    <p>• Notes of <span>minerality,</span> reflecting the unique terroir of Kardenakhi(Kardeneli
                        Maciej Notes).</p>
                </p>

                <p className="product_text_desc">
                    This wine evolves with every minute in the glass, revealing deeper complexities of flavor.
                    Enjoy upon opening, after 15 minutes, and again after an hour to savor its full range.
                </p>

                <p className="product_text_desc">
                    <span>Limited Production</span>
                </p>

                <p className="product_text_desc">
                    Only <span>2,300 bottles of Kardeneli 2022</span> have been produced.
                    Store in your personal cellar for up to 7 years, as the premium cork ensures its continued
                    aging and development over time.
                </p>

                <p className="product_text_desc">
                    <span>The Perfect Companion for Celebrations</span>
                </p>

                <p className="product_text_desc">
                    Whether shared with friends or enjoyed in quiet reflection, <span>Kardeneli 2022</span> is a wine that elevates any occasion. Its moderate body and smooth tannins make it an ideal companion for happy, friendly gatherings. <span>A glass of Kardeneli is a toast to good health, friendship, and the art of winemaking.</span>
                </p>

                <p className="product_text_desc">
                    <span>Raise your glass to the beauty of tradition and the joy of discovery with Kardeneli 2022.</span>
                </p>


            </div>,
            quote: "",
        },
        testing_notes: {
            with_score: null,
            common_notes: [
                {
                    title: 'In July 2024 by judge pannel USA wine competition:',
                    text: 'This bold red wine offers aromas of dark plum, black cherry, and toasted baking spices, with a juicy palate featuring cola and dried berries, finishing with firm tannins and earthy complexity.'
                }
            ],
            description:  {
                title: 'Kardeneli Saperavi 2022',
                text: '2022 saw a slightly drier year, which concentrated the flavors in the grapes, resulting in bold red wine with more pronounced dried fruit flavors. This vintage presents aromas of dark plum, black cherry, and toasted baking spices, with a juicy palate featuring cola and dried berries. The firm tannins and earthy complexity create a robust structure(TASTING NOTES 2024 -2). This dryness provided a unique depth to the 2022 vintage, making it stand out for its richness and bold flavors'
            }
        },
        video: null
    },
    {
        id: 4,
        photo: photo21_k23_1,
        name: "Kardeneli Red 2023",
        cardDetails: "",
        details: "",
        slug: 'kardeneli-red-dry',
        year: '2023',
        reward_img: null,
        reward_file: null,
        gallery: [photo21_k23_1, photo21_k23_2, photo21_k23_1, photo21_k23_2],
        gallery_origin: [photo21_k23_1, photo21_k23_2, photo21_k23_1, photo21_k23_2],
        general: {
            cover: false,
            details: "Red, Dry Natural Wine, Vintage: 2023",
            varieties: "Saperavi 100%",
            alcohol: "12.3%",
            origin: "Akhoebi Microzone, Kardenakhi village, Kakheti region, East Georgia",
            fermentation: "Naturally fermented by traditional Georgian way, maceration goes with the grape skins.",
            description: <div className="product_text_block">
                <p className="product_text_desc">
                    <span>Red Dry Wine Crafted from the Heart of Georgia's Vineyards</span>
                </p>
                <p className="product_text_desc">
                    Welcome to the exquisite experience of <span>Kardeneli 2023 Saperavi,</span> a wine that embodies the
                    soul of Kardenakhi village, nestled in the heart of Georgia’s renowned Kakheti wine region.
                    Handcrafted with passion and tradition, this limited-edition wine is cultivated from
                    centuries-old Saperavi vines, grown in the mineral-rich soils of the Akhoebi-Tsaraphi
                    microzone, ensuring the finest quality from every harvest.
                </p>

                <p className="product_text_desc">
                    <span>A Masterpiece from the Vineyards</span>
                </p>

                <p className="product_text_desc">
                    Our family vineyards, perched on the terraces of Kardenakhi, overlook the majestic Alazani
                    Valley. Rich, stony, clay dry-farmed soils imbued with natural minerals create a harmonious
                    environment for growing grapes that are both healthy and flavorful.
                </p>

                <p className="product_text_desc">
                    At <span>Kardeneli</span>, every grape is carefully nurtured, focusing on quality over
                    quantity. The result? Grapes that yield a high sugar content, rich acids, and essential
                    nutrients, making for a truly exceptional wine.
                </p>

                <p className="product_text_desc">
                    <span>Why Choose Kardeneli 2023 Red?</span>
                </p>

                <p className="product_text_desc">
                    <p>• <span>Handcrafted Excellence</span> – Family-owned and maintained for generations with
                        minimal intervention, allowing nature to thrive in balance.</p>
                    <p>• <span>Naturally Fermented</span> on wild yests– Fermented on the skins for four weeks
                        without added yeast, ensuring the purest expression of natural flavors.</p>
                    <p>• <span>No Pressing Needed, Free-run wine</span> – Gravity-fed extraction preserves the
                        purity of the wine, free from bitterness and harsh tannins.</p>
                    <p>• <span>Aged to Perfection</span> – fermented with minimal sulfur, aged and rests in
                        bottles since bottling after one year of ravest, ensure secondary fermentation for taste
                        perfection, Kardeneli offers a clean, pure finish, with an alcohol content of 12.3%.</p>
                </p>


                <p className="product_text_desc">
                    <span>Taste the Legacy</span>
                </p>

                <p className="product_text_desc">
                    With each sip, experience the carefully layered flavors
                    of <span>Kardeneli 2023 Saperavi</span>:
                </p>

                <p className="product_text_desc">
                    <p>• <span>Aromas </span> of intense blackberry, dark cherry, and plum accented by toasted
                        cinnamon and clove.</p>
                    <p>• A <span>full-bodied palate </span> of juicy fruit with chewy tannins and a long, smooth
                        finish.</p>
                    <p>• Notes of <span>minerality,</span> reflecting the unique terroir of Kardenakhi(Kardeneli
                        Maciej Notes).</p>
                </p>

                <p className="product_text_desc">
                    This wine evolves with every minute in the glass, revealing deeper complexities of flavor.
                    Enjoy upon opening, after 15 minutes, and again after an hour to savor its full range.
                </p>

                <p className="product_text_desc">
                    <span>Limited Production</span>
                </p>

                <p className="product_text_desc">
                    Only <span>2,400 bottles of Kardeneli 2023</span> have been produced.
                    Store in your personal cellar for up to 7 years, as the premium cork ensures its continued
                    aging and development over time.
                </p>

                <p className="product_text_desc">
                    <span>The Perfect Companion for Celebrations</span>
                </p>

                <p className="product_text_desc">
                    Whether shared with friends or enjoyed in quiet reflection, <span>Kardeneli 2023</span> is a wine that elevates any occasion. Its moderate body and smooth tannins make it an ideal companion for happy, friendly gatherings. <span>A glass of Kardeneli is a toast to good health, friendship, and the art of winemaking.</span>
                </p>

                <p className="product_text_desc">
                    <span>Raise your glass to the beauty of tradition and the joy of discovery with Kardeneli 2023.</span>
                </p>


            </div>,
            quote: "",
        },
        testing_notes: {
            with_score: null,
            common_notes: null,
            description:  {
                title: 'Weather and Its Impact on the Vintages',
                text: 'The unique, dry-farmed vineyards of Kardeneli are greatly influenced by the weather each year. Since no irrigation is used, the variation in rainfall, sunlight, and temperature directly shapes the character of each vintage. In 2019, the ideal weather conditions led to a well-balanced wine. 2021 was another strong year, delivering a perfect balance between sugar and acidity. However, the drier conditions in 2022 concentrated the fruit flavors even more, while the cooler, rainier 2023 vintage is expected to result in a smoother, more rounded wine.'
            }
        },
        // only for 2023 - content from testing notes
        video: true
    },
    {
        id: 5,
        photo: photo21_vel19_1,
        name: "Velise Amber 2019",
        cardDetails: "",
        slug: 'velise-amber-dry-kvevri',
        year: '2019',
        reward_img: reward1,
        reward_file: velise,
        gallery: [photo21_vel19_1, photo21_vel19_2, photo21_vel19_3, photo21_vel19_4],
        gallery_origin: [photo21_vel19_1, photo21_vel19_2_origin, photo21_vel19_3_origin, photo21_vel19_4_origin],
        general: {
            name: "Velise",
            details: "Amber, Dry, Kvevri Natural Wine, Vintage: 2019",
            varieties: "Rkatsiteli 100%",
            alcohol: "12.2%",
            cover: false,
            origin: "Tsarapi Microzone, Kardenakhi Village, Kakheti Region, East Georgia",
            fermentation: "Naturally fermented and aged in Kvevri-ceramic egg shape vessels, buried in the ground of cellar.",
            description: <div className="product_text_block">

                <p className="product_text_desc">
                    <span>Amber Dry Wine Crafted from an Ancient Blend of Georgian Grapes</span>
                </p>

                <p className="product_text_desc">
                    Experience the unique character of <span>Velise Amber Dry Wine,</span> produced from the
                    Tsaraphi microzone in the famed Kakheti region of Georgia. This exceptional amber wine is a
                    product of a natural blend of three ancient Georgian grape varieties: <span>Rkatsiteli, Khikhvi,</span> and <span>Mtsvane</span>.
                    Together, they create a harmonious and unforgettable wine that showcases the true essence of
                    Georgian winemaking traditions.
                </p>

                <p className="product_text_desc">
                    <span>A Historic Vineyard, A Unique Blend</span>
                </p>

                <p className="product_text_desc">
                    The Tsaraphi microzone, where the Velise vineyards are located, is renowned for its
                    exceptional soil composition and mineral richness, which adds distinctive character to its
                    wines. The vineyards, perched on dry-farmed terraces overlooking the Alazani Valley, are
                    managed with a focus on natural balance and sustainable farming practices.
                </p>

                <p className="product_text_desc">
                    At <span>Velise</span>, the grape varieties are not separated. Instead, the vineyard’s
                    natural mix of <span>Rkatsiteli, Khikhvi, and Mtsvane</span> grapes are harvested together
                    and fermented together to create what is known as the <span>Tsaraphi blend</span>. This
                    ancient tradition results in a wine that harmoniously brings together the best
                    characteristics of each grape variety. The rich, golden-amber hue of the wine is achieved
                    through traditional Georgian qvevri (clay vessel) fermentation methods, offering an
                    authentic taste of the Kakheti terroir.
                </p>

                <p className="product_text_desc">
                    <span>What Makes Velise Amber Dry Stand Out?</span>
                </p>

                <p className="product_text_desc">
                    <p>• <span>A Unique Grape Blend – </span>The Tsaraphi blend of Rkatsiteli, Khikhvi, and
                        Mtsvane grapes gives the wine its distinctive complexity, combining floral, fruity, and
                        earthy notes.</p>
                    <p>• <span>Traditional Georgian Methods </span>Produced using ancient winemaking techniques
                        in qvevris, which allow for skin contact fermentation, resulting in its striking amber
                        color and rich tannic structure.</p>
                    <p>• <span>Terroir-Driven – </span> The Tsaraphi microzone’s mineral-rich, dry-farmed soils
                        provide depth and a hint of salinity, enhancing the wine’s flavor profile and adding
                        complexity to every sip.</p>
                </p>

                <p className="product_text_desc">
                    With each glass of <span>Velise Amber Dry</span>, you can experience:
                </p>

                <p className="product_text_desc">
                    <p>• Aromas of<span>ripe apricots, pear,</span> and subtle hints of honey and dried herbs.</p>
                    <p>• A <span>full-bodied palate </span> with flavors of stone <span>fruits, orange</span> zest, and <span>floral undertones</span>, underpinned by fine tannins and a long, <span>mineral-driven finish.</span></p>
                    <p>• The wine’s rich amber color and robust structure are balanced by lively acidity, creating a harmonious and satisfying drinking experience.</p>
                </p>

                <p className="product_text_desc">
                    <span>The Influence of Tsaraphi’s Terroir</span>
                </p>

                <p className="product_text_desc">
                    The dry-farmed vineyards of Tsaraphi rely on nature’s balance, with no irrigation to support the vines. This means that <span>the weather plays a key role</span> in shaping the character of each vintage. The mineral-rich soils and diverse microclimate of the Tsaraphi region imbue the grapes with complexity and depth, leading to wines that are expressive and unique. The natural blend of grapes and the traditional fermentation techniques combine to create an amber wine that is bold, yet refined.
                </p>

                <p className="product_text_desc">
                    <span>Limited Production</span>
                </p>

                <p className="product_text_desc">
                    <span>Velise Amber Dry</span> is a limited-edition wine that offers a true taste of Georgia’s ancient winemaking heritage. With only a small number of bottles produced each year, this amber dry wine is a rare gem, perfect for those looking to explore the depths of Georgian wine cultur
                </p>

                <p className="product_text_desc">
                    <span>Perfect for Pairing or Cellaring</span>
                </p>

                <p className="product_text_desc">
                    Whether enjoyed with a meal or aged in a personal
                    collection, <span>Velise Amber Dry</span> continues to evolve with time. Its structured
                    tannins and lively acidity make it ideal for pairing with rich dishes, while its potential
                    for aging promises even greater complexity and depth in the years to come.
                </p>

                <p className="product_text_desc">
                    <span>Discover the rich heritage of Georgian winemaking with Velise Amber Dry – a true expression of the Tsaraphi microzone.</span>
                </p>
            </div>
            ,

        },

        testing_notes: {
            common_notes: [
                {
                    title: 'Testing notes at USA Wine Competition 2023',
                    text: 'Toasted almonds, nutty, honey, blue fruit aromas on nose with flavours of caramel, apple skin and quince on palate.'
                }
            ],
        },
        video: null
    },

]
