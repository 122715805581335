import React, {useEffect, useRef} from "react";
import "./Selection.scss"
import Slider from "react-slick";
import {responsive} from "../../components/Tabs/sliderResponsive";
import ProductCard from "../../components/ProductCard/ProductCard";
import {Products} from "../../WebsiteData/WinesData";

function Selection(): React.ReactNode{

    const slider2 = useRef();

    const cards = Products.map(wineCard =>
        <ProductCard
            slug={`/${wineCard?.year}/${wineCard?.slug}/description`}
            photo={wineCard?.photo}
            key={wineCard?.id}
            name={wineCard?.name}
            cardDetails={wineCard?.cardDetails}/>
    )


    return (
        <div id="collection_items" className="selection_section">

            <div className="product_cards">
                <div className="selection_collection">
                    <Slider
                        slidesToShow={3}
                        centerMode={false}
                        infinite={true}
                        className={"center"}
                        dots={true}
                        arrows={false}
                        ref={slider => (slider2.current = slider)}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        responsive={responsive}>
                        {cards}
                    </Slider>
                </div>
            </div>


            {/*<Tabs className='tablinks_section'>*/}
            {/*    <TabContent title={`${isDesktop ? "Red Wine" : "Red"}`}>*/}
            {/*        <RedWines/>*/}
            {/*    </TabContent>*/}
            {/*    <TabContent title={`${isDesktop ? "Amber Wines" : "Amber"}`}>*/}
            {/*        <AmberWines/>*/}
            {/*    </TabContent>*/}
            {/*    /!*<TabContent title={`${isDesktop ? "White Wine" : "White"}`}>*!/*/}
            {/*    /!*    <WhiteWines/>*!/*/}
            {/*    /!*</TabContent>*!/*/}
            {/*    <TabContent title="Chacha">*/}
            {/*        <Chacha/>*/}
            {/*    </TabContent>*/}
            {/*</Tabs>*/}
        </div>
    )
}

export default Selection;
