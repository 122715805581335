import React from "react";
import "./Menu.scss"
import sprite from "../../../assets/sprite.svg"
import {HashLink} from "react-router-hash-link";

export interface Props {
    menu: boolean;
    handleMenu: any,
    handleLink: any,
}


function Menu(props: Props) : React.ReactNode {
    return(
        <div className="menu-block">
            <button className="hamburger_menu_btn" onClick={props.handleMenu}>
                {
                    props.menu ?
                        <svg className="icon close-icon">
                            <use xlinkHref={sprite + "#close"}></use>
                        </svg>
                        :
                        <svg className="icon menu-icon">
                            <use xlinkHref={sprite + "#hamburger-menu"}></use>
                        </svg>
                }
            </button>
            <div className={`menu_list ${props.menu ? "active" : ""}`}>
                <div className="menu_content">
                    <div className="menu_item">
                        <HashLink className="menu_item_link" onClick={props.handleMenu} to="/#collection_items" smooth={true}>SELECTION</HashLink>
                    </div>
                    <div className="menu_item">
                        <HashLink className="menu_item_link" onClick={props.handleMenu} to="/#know_more" smooth={true} >KNOW MORE</HashLink>

                    </div>
                    <div className="menu_item">
                        <HashLink className="menu_item_link" onClick={props.handleMenu} to="/#contact" smooth={true}>CONTACT US</HashLink>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Menu
