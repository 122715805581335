import React, {useState} from "react";
import { Formik } from 'formik';
import * as Yup from 'yup';
import "yup-phone";

import sprite from "../../assets/sprite.svg"
import "./ContactForm.scss"

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const SignupSchema = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    companyName: Yup.string()
        .required('Required'),
});

// const [status, setStatus] = useState('')
// export interface Props {
//     status: string;
//     setStatus: any;
// }

function ContactForm() : React.ReactNode{
    const [status, setStatus] = useState('')
    return(
        <Formik
            initialValues={{
                name: '',
                phone: '',
                email: '',
                companyName: '',
                subject: '',
            }}
            validationSchema={SignupSchema}
            validator={() => ({})}
            onSubmit = {(values, actions) => {
                setStatus('sending')
                const data = new FormData();
                data.append('name', values.name);
                data.append('phone', values.phone);
                data.append('email', values.email);
                data.append('companyName', values.companyName);
                data.append('subject', values.subject);

                fetch('mail/contact_form.php',{
                    method: 'POST',
                    body: data
                }).then((res) => {
                    return res.json()
                }).then((data) => {
                    if(data.succes) {
                        actions.resetForm();
                        setStatus('success')
                    }
                    if(data.error) {
                        setStatus('error')
                    }
                })
            }}
        >
            {props => (
                <form className="contact_form" onSubmit={props.handleSubmit}>
                    <div className="input_section">
                        <div className="input_section_icon">
                            <svg className="icon user-icon">
                                <use xlinkHref={sprite + "#user"}></use>
                            </svg>
                        </div>
                        <input
                            autoComplete="off"
                            className="contact_input"
                            type="text"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.name}
                            name="name"
                            placeholder="Firstname/Lastname"
                        />
                        {props.errors.name && <small className="small" id="feedback">{props.errors.name}</small>}                </div>
                    <div className="contact_details">
                        <div className="input_section">
                            <div className="input_section_icon">
                                <svg className="icon phone-icon">
                                    <use xlinkHref={sprite + "#phone"}></use>
                                </svg>
                            </div>
                            <input
                                autoComplete="off"
                                className="contact_input"
                                type="text"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.phone}
                                name="phone"
                                placeholder="Phone"
                            />
                            {props.errors.phone && <small className="small" id="feedback">{props.errors.phone}</small>}
                        </div>
                        <div className="input_section">
                            <div className="input_section_icon">
                                <svg className="icon mail-icon">
                                    <use xlinkHref={sprite + "#mail"}></use>
                                </svg>
                            </div>
                            <input
                                autoComplete="off"
                                className="contact_input"
                                type="text"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.email}
                                name="email"
                                placeholder="Email"
                            />
                            {props.errors.email && <small className="small" id="feedback">{props.errors.email}</small>}
                        </div>
                    </div>
                    <div className="input_section">
                        <div className="input_section_icon">
                            <svg className="icon home-icon">
                                <use xlinkHref={sprite + "#home"}></use>
                            </svg>
                        </div>
                        <input
                            autoComplete="off"
                            className="contact_input"
                            type="text"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.companyName}
                            name="companyName"
                            placeholder="Company name"
                        />
                        {props.errors.companyName && <small className="small" id="feedback">{props.errors.companyName}</small>}
                    </div>
                    <div className="input_section description_input">
                        <div className="input_section_icon">
                            <svg className="icon comment-icon">
                                <use xlinkHref={sprite + "#comment"}></use>
                            </svg>
                        </div>
                        <textarea
                            autoComplete="off"
                            className="contact_input"
                            type="text"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.subject}
                            name="subject"
                            placeholder="Subject"
                        />
                    </div>
                    {
                        status === 'sending' &&
                        <p className="form_message">Sending...</p>

                    }
                    { status === 'success'  &&
                    <p className="form_message">Congratulations! Your message has been sent successfully!</p>
                    }
                    { status === 'error'  &&
                    <p className="form_message error">Something went wrong</p>
                    }
                    <div className="submit_section">
                        {
                            status === 'sending' ?
                                <button disabled={true} className="contact_submit" type="submit">
                                    <div className="btn_text">
                                        Send mail
                                    </div>
                                    <div className="btn_icon disabled_icon">
                                        <svg className="icon sending-icon">
                                            <use xlinkHref={sprite + "#sending"}></use>
                                        </svg>
                                    </div>
                                </button>
                                :
                                <button className="contact_submit" type="submit">
                                    <div className="btn_text">
                                        Send mail
                                    </div>
                                    <div className="btn_icon">
                                        <svg className="icon">
                                            <use xlinkHref={sprite + "#send"}></use>
                                        </svg>
                                    </div>
                                </button>
                        }
                        <div className="submit_info">
                            <div className="submit_info_mail">
                                <svg className="icon mail-icon">
                                    <use xlinkHref={sprite + "#mail"}></use>
                                </svg>
                                <div className="submit_info_mail_text">
                                    <p>gaumarjos@ssw.ge</p>
                                </div>
                            </div>
                            <h5>Sommelier Selection LLC Georgia</h5>
                        </div>
                    </div>
                </form>
            )}
        </Formik>
    )
}

export default ContactForm;