import React, {useEffect} from "react";
import { animateScroll as scroll } from "react-scroll";
import "./ScrollTop.scss";

import sprite from "../../assets/sprite.svg"

function ScrollTop() : React.ReactNode {
    useEffect(() => {
        const header = document.getElementById("scroll");

        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > 500) {
                header.classList.add("active");
            } else {
                header.classList.remove("active");
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, [])
    return (
        <button id="scroll" onClick={() => scroll.scrollToTop()} className="action_btn scrolltop_btn">
            <svg className="icon chevron-icon">
                <use xlinkHref={sprite + "#chevron-right"}></use>
            </svg>
        </button>
    )
}

export default ScrollTop;