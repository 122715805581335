import React from "react";
import "./ProductCard.scss";
import ScreenSize from "../../helpers/ScreenSize";
import { Link } from "react-router-dom";

interface Props {
    name: string;
    cardDetails: string;
    photo: any;
    slug: any;
}


function ProductCard({name, cardDetails, photo, slug}: Props) : React.ReactNode {
    const {isDesktop} = ScreenSize();

    return (
        <div className="product_card_section">
            <div className="product_card">
                <div className="product_card_image">
                    <img src={photo} alt="wine"/>
                </div>
                <div className="product_card_info">
                    <h2 className="product_name">{name}</h2>
                    <p className="description">{cardDetails}</p>
                    <Link to={slug} offset={isDesktop ? -100 : -80} className="" spy={true} smooth={true} duration={600}>
                        <div className="primary-btn">
                            See more
                        </div>
                    </Link>
                </div>
            </div>
        </div>
)
}

export default ProductCard;
