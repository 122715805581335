import React from "react";

import "./Motto.scss"

function Motto() : React.ReactNode {
    return (
        <div className="motto_section">
            <div className="motto">
                <p className="motto_text">WINES FOR WINNERS</p>
            </div>
        </div>
    )
}


export default Motto;