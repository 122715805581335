import React, {useState, useEffect, useMemo, useCallback} from "react";
import { animateScroll as scroll } from "react-scroll";
import brand from "../../assets/brand.svg";
import ScreenSize from "../../helpers/ScreenSize";

import Menu from "./Menu/Menu";
import "./Header.scss";
import {Link, useNavigate, useParams} from "react-router-dom";
import {HashLink} from "react-router-hash-link";

function Header() : React.ReactNode {
    const {isDesktop} = ScreenSize();
    const params = useParams()
    const navigate = useNavigate()

    const isProductPage = useMemo(() => Object.keys(params)?.length > 0 , [params])

    const [menu, setMenu] = useState(false);


    useEffect(() => {
        const header = document.getElementById("header");

        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > 115) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, [])

    const handleMenu = () => {
        setMenu(prevState => !prevState);
    }

    const handleLink = () => {
        setMenu(false)
    }

    const handleLogoClick = useCallback(() => {
        navigate('/')
        scroll.scrollToTop()
    }, [])


    return (
        <div id="header" style={{background: isProductPage ? '#000' : 'transparent'}} className='header_wrapper'>
            <header className={`header ${menu ? "active" : ""}`}>
                <div className="section_container header_container">
                    <div className="header_content">
                        <div className="brand">
                            <div className="brand_link" onClick={handleLogoClick}>
                                <img src={brand} alt="sommelier-selection"/>
                            </div>
                        </div>
                        {
                            isDesktop ?
                                <nav className="header_nav">
                                    <div className="header_nav_element">
                                        <HashLink className="header_nav_link" to="/#collection_items"  smooth={true} >SELECTION</HashLink>
                                    </div>
                                    <div className="header_nav_element">
                                        <HashLink className="header_nav_link" to="/#know_more" smooth={true} >KNOW MORE</HashLink>
                                    </div>
                                    <div className="header_nav_element">
                                        <HashLink className="header_nav_link" to="/#contact" smooth={true}>CONTACT US</HashLink>
                                    </div>
                                </nav> :
                                <Menu handleLink={handleLink} menu={menu} handleMenu={handleMenu} />
                        }
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header;
