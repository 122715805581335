import React from "react";
import "./Footer.scss";

import logo from "../../assets/minicode_logo.svg"
import sprite from "../../assets/sprite.svg"

function Footer() :React.ReactNode {
    return (
        <footer className="footer">
            <div className="section_container">
                <div className="copyright copyright_mobile">
                    <span className="copyright_text">2021 Sommelier Selection Wines of Georgia.</span>
                </div>
                <div className="footer_content">
                    <div className="copyright copyright_desktop">
                        <span className="copyright_text">2021 Sommelier Selection Wines of Georgia.</span>
                    </div>
                    <div className="instagram">
                        <a href="https://www.instagram.com/kardeneli_wine_georgia/" target="_blank" rel="noreferrer" className="social_link">
                            <svg className="icon">
                                <use xlinkHref={sprite + "#instagram"}></use>
                            </svg>
                            Instagram
                        </a>
                    </div>
                    <div className="develop_company">
                        <span>Made by</span>
                        <a href="https://minicode.md/" target="_blank" rel="noreferrer" className="develop_company_logo">
                            <img src={logo} alt="minicode"/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;