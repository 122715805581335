import {Outlet} from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Contact from "../App/Contact/Contact";

const MasterLayout = () => {

    return (
        <div className="App">
            <Outlet/>
            <Contact />
            <Footer/>
        </div>
    );
};

export default MasterLayout;
