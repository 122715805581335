export const responsive = [
    {
        breakpoint: 640,
        settings: {
            slidesToShow: 1,
            centerMode: true,
            infinite: true,
            // centerPadding: 50,
        }
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            centerMode: true,
            infinite: true,
        }
    },
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            centerMode: true,
            infinite: true,
            centerPadding: '0px',
        }
    },
    {
        breakpoint: 1280,
        settings: {
            slidesToShow: 3,
            centerMode: true,
            infinite: true,
            centerPadding: '0px',
        }
    },
    {
        breakpoint: 1536,
        settings: {
            slidesToShow: 3,
            centerMode: true,
            infinite: true,
            centerPadding: '0px'
        }
    },
    {
        breakpoint: 1920,
        settings: {
            slidesToShow: 3,
            centerMode: true,
            infinite: true,
            centerPadding: '0px'
        }
    },
    {
        breakpoint: 1930,
        settings: {
            slidesToShow: 3,
            centerMode: true,
            centerPadding: '0px',
            infinite: true
        }
    }
]
